// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./car-pic.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".t_c[data-v-7b416792] {\n  text-align: center;\n}\n.fade-enter-active[data-v-7b416792],\n.fade-leave-active[data-v-7b416792] {\n  -webkit-transition: opacity 0.5s;\n  transition: opacity 0.5s;\n}\n.fade-enter[data-v-7b416792],\n.fade-leave-to[data-v-7b416792] {\n  opacity: 0;\n}\n.infoWrapper[data-v-7b416792] {\n  margin: 20px auto;\n  overflow: hidden;\n}\n.infoWrapper .fl[data-v-7b416792] {\n  float: left;\n  width: 600px;\n}\n.infoWrapper .fl .content[data-v-7b416792] {\n  width: 250px;\n  float: left;\n  line-height: 60px;\n  padding-left: 30px;\n}\n.infoWrapper .fl[data-v-7b416792]:nth-of-type(1) {\n  width: 170px;\n}\n.infoWrapper .fl:nth-of-type(1) .img[data-v-7b416792] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 12px center;\n  margin-left: 70px;\n  width: 150px;\n  height: 150px;\n}\n.mask[data-v-7b416792] {\n  background: rgba(0,0,0,0) no-repeat center/contain;\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 999;\n}\n", ""]);
// Exports
module.exports = exports;
