<template>
  <div class="page1">
    <div class="content">
      <!--搜索条件区域-->
      <div
        class="searchWrapper"
        @keydown.enter.prevent="
          pageNum = 1;
          searchData();
        "
      >
        <el-form
          :inline="true"
          label-position="right"
          :rules="rules"
          ref="searchForm"
          :model="formInline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Tag_Name')">
                <el-input v-model="formInline.labelName" placeholder="请输入标签名称简拼" />
              </el-form-item>
              <el-form-item label="创建人">
                <el-input v-model="formInline.creatorName" placeholder="请输入创建人姓名" />
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                type="primary"
                icon="el-icon-search"
                :loading="loading"
                @click="
                  pageNum = 1;
                  searchData();
                "
                >查询
              </AuthorityComponent>
            </div>
          </div>

          <!-- <el-button type="primary" @click="pageNum=1;searchData()" :loading="loading">{{ $t('button.search') }}</el-button> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <AuthorityComponent
            ComponentName="el-table-column"
            :permission="['button.view']"
            align="center"
            label="操作"
            width="140"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="toDetail(scope.row)">查看</el-button>
            </template>
          </AuthorityComponent>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setIndex } from "../../common/js/public.js";
export default {
  name: "userTag",
  data() {
    return {
      pageNum: 1,
      pageSize: 15,
      total: 0,
      rules: {},
      formInline: {},
      loading: false,
      tableData: [],
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "labelName",
          label: "标签名称",
        },
        {
          prop: "userNumber",
          label: "人数（人）",
        },
        {
          prop: "labelDesc",
          label: "标签描述",
        },
        {
          prop: "creatorName",
          label: "创建人",
        },
        {
          prop: "createTime",
          label: this.$t("list.peration_date"),
        },
      ],
    };
  },
  mounted() {
    // this.searchData()
  },
  activated() {
    this.searchData();
  },
  methods: {
    // 获取标签列表
    searchData() {
      this.loading = true;
      const opt = {
        url: "/acb/2.0/labelManage/labelList",
        method: "get",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          labelName: this.formInline.labelName,
          creatorName: this.formInline.creatorName,
        },
        success: (res) => {
          this.tableData = setIndex(this.pageNum, res.value.list);
          this.total = parseInt(res.value.total);
          this.loading = false;
        },
        fail: (err) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 查看详情页
    toDetail(row) {
      this.$router.push({ path: "/labelManageDetail", query: { labelId: row.labelId } });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.t_c {
  text-align: center;
}
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0
  }
.infoWrapper {
  margin: 20px auto;
  overflow: hidden;
}
.infoWrapper .fl {
  float: left;
  width: 600px;
  .content {
    width: 250px;
    float: left;
    line-height: 60px;
    padding-left: 30px;
  }
}
.infoWrapper .fl:nth-of-type(1){
  width: 170px;
  .img {
    background: url('./car-pic.png') no-repeat 12px center;
    margin-left: 70px
    width: 150px;
    height: 150px;
  }
}
.mask
	background: rgba(0,0,0,0) no-repeat center/contain;
	// background: rgba(49,53,65,0.60) no-repeat center/contain;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;
</style>
<style scoped>
.info {
  width: 565px;
}
.info li {
  overflow: hidden;
  border-left: 1px solid #ebeef5;
  border-top: 1px solid #ebeef5;
}

.info li div {
  float: left;
  min-height: 30px;
  line-height: 30px;
  border-right: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}

.title {
  line-height: 50px;
  border: 1px solid #ebeef5;
  border-bottom: 0;
  padding-left: 10px;
  color: skyblue;
}

.item-name {
  width: 100px;
  text-align: right;
}

.item-img {
  width: 281px;
  text-align: center;
  vertical-align: middle;
}

.item-img img {
  width: 80%;
  vertical-align: middle;
}

.item-content {
  width: 180px;
  text-align: center;
}

.balance {
  display: inline-block;
  padding: 2px 15px;
  line-height: 18px;
}

.item-img-wrapper {
  min-height: 124px !important;
  line-height: 124px !important;
  /* // border-bottom: 1px solid #ebeef5; */
}
.showEmp {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  width: 65px;
}
.item-img-wrapper .item-name,
.item-img-wrapper .item-content,
.item-img {
  height: 124px !important;
  line-height: 124px !important;
  /* // border-bottom:none!important; */
}
.el-tag {
  margin-left: 10px;
}
.img {
  background: url("car-pic.png") center no-repeat;
}
</style>
